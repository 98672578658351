import {
  GET_SINGLE_PROFILE_FAIL,
  GET_SINGLE_PROFILE_REQUEST,
  GET_SINGLE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_COMPANY_PROFILE_FAIL,
  UPDATE_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_PROFILE_SUCCESS,
  CLEAR_PROFILE_RECORD,
} from "../constant/ProfileConstant";
const initialState = {
  loading: false,
  action: "Profile",
  result: [],
  singledata: [],
  message: "",
  error: "",
  success: false,
};
const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.message,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case UPDATE_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.message,
      };
    case UPDATE_COMPANY_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_PROFILE_RECORD:
      return {
        ...state,
        loading: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
