// constant files

export const ADD_EMPLOYEE_FAMILY_TYPE_REQUEST =
  "ADD_EMPLOYEE_FAMILY_TYPE_REQUEST";
export const ADD_EMPLOYEE_FAMILY_TYPE_SUCCESS =
  "ADD_EMPLOYEE_FAMILY_TYPE_SUCCESS";
export const ADD_EMPLOYEE_FAMILY_TYPE_FAIL = "ADD_EMPLOYEE_FAMILY_TYPE_FAIL";

export const UPDATE_EMPLOYEE_FAMILY_TYPE_REQUEST =
  "UPDATE_EMPLOYEE_FAMILY_TYPE_REQUEST";
export const UPDATE_EMPLOYEE_FAMILY_TYPE_SUCCESS =
  "UPDATE_EMPLOYEE_FAMILY_TYPE_SUCCESS";
export const UPDATE_EMPLOYEE_FAMILY_TYPE_FAIL =
  "UPDATE_EMPLOYEE_FAMILY_TYPE_FAIL";

export const DELETE_EMPLOYEE_FAMILY_TYPE_REQUEST =
  "DELETE_EMPLOYEE_FAMILY_TYPE_REQUEST";
export const DELETE_EMPLOYEE_FAMILY_TYPE_SUCCESS =
  "DELETE_EMPLOYEE_FAMILY_TYPE_SUCCESS";
export const DELETE_EMPLOYEE_FAMILY_TYPE_FAIL =
  "DELETE_EMPLOYEE_FAMILY_TYPE_FAIL";

export const GETALL_EMPLOYEE_FAMILY_TYPE_REQUEST =
  "GETALL_EMPLOYEE_FAMILY_TYPE_REQUEST";
export const GETALL_EMPLOYEE_FAMILY_TYPE_SUCCESS =
  "GETALL_EMPLOYEE_FAMILY_TYPE_SUCCESS";
export const GETALL_EMPLOYEE_FAMILY_TYPE_FAIL =
  "GETALL_EMPLOYEE_FAMILY_TYPE_FAIL";

export const GETDETAIL_EMPLOYEE_FAMILY_TYPE_REQUEST =
  "GETDETAIL_EMPLOYEE_FAMILY_TYPE_REQUEST";
export const GETDETAIL_EMPLOYEE_FAMILY_TYPE_SUCCESS =
  "GETDETAIL_EMPLOYEE_FAMILY_TYPE_SUCCESS";
export const GETDETAIL_EMPLOYEE_FAMILY_TYPE_FAIL =
  "GETDETAIL_EMPLOYEE_FAMILY_TYPE_FAIL";
export const CLEAR_EMPLOYEE_FAMILY_RECORD = "CLEAR_EMPLOYEE_FAMILY_RECORD";
