import {
     GET_ALL_COMPANY_FAIL,
  GET_ALL_COMPANY_REQUEST,
  GET_ALL_COMPANY_SUCCESS,
  GET_SINGLE_COMPANY_FAIL,
  GET_SINGLE_COMPANY_REQUEST,
  GET_SINGLE_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_FAIL,
 
  
  COMPANY_CHANGE_STATUS_FAIL,
  COMPANY_CHANGE_STATUS_REQUEST,
  COMPANY_CHANGE_STATUS_SUCCESS,
} from "../constant/CompanyConstant";
const initialState = {
  loading: false,
  action: "Company",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case DELETE_COMPANY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        result: state.result.filter(
          (item) => item.id !== action.result
        ),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_COMPANY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,

        msg: action.msg,
      };
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case COMPANY_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case COMPANY_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,

        msg: action.msg,
      };
    case COMPANY_CHANGE_STATUS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SINGLE_COMPANY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SINGLE_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default CompanyReducer;
