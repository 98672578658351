// constant files

export const ADD_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";

export const UPDATE_COUNTRY_REQUEST = "UPDATE_COUNTRY_REQUEST";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL";

export const DELETE_COUNTRY_REQUEST = "DELETE_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL";

export const GET_ALL_COUNTRY_REQUEST = "GET_ALL_COUNTRY_REQUEST";
export const GET_ALL_COUNTRY_SUCCESS = "GET_ALL_COUNTRY_SUCCESS";
export const GET_ALL_COUNTRY_FAIL = "GET_ALL_COUNTRY_FAIL";

export const GET_SINGLE_COUNTRY_REQUEST = "GET_SINGLE_COUNTRY_REQUEST";
export const GET_SINGLE_COUNTRY_SUCCESS = "GET_SINGLE_COUNTRY_SUCCESS";
export const GET_SINGLE_COUNTRY_FAIL = "GET_SINGLE_COUNTRY_FAIL";
