import {
  ADD_LEAVE_APPROVE_FAIL,
  ADD_LEAVE_APPROVE_SUCCESS,
  ADD_LEAVE_APPROVE_REQUEST,
  DELETE_LEAVE_REQUEST,
  DELETE_LEAVE_SUCCESS,
  GETDETAIL_LEAVE_REQUEST,
  GETDETAIL_LEAVE_FAIL,
  GETDETAIL_LEAVE_SUCCESS,
  UPDATE_LEAVE_APPROVE_FAIL,
  UPDATE_LEAVE_APPROVE_SUCCESS,
  UPDATE_LEAVE_APPROVE_REQUEST,
  DELETE_LEAVE_FAIL,
  GETALL_LEAVE_FAIL,
  GETALL_LEAVE_SUCCESS,
  GETALL_LEAVE_REQUEST,
  CLEAR_LEAVE_RECORD,
} from "../constant/LeaveConstant";

const initialState = {
  loading: false,
  isloading: false,
  action: "Leave",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const LeaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GETDETAIL_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETDETAIL_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GETDETAIL_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case ADD_LEAVE_APPROVE_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case ADD_LEAVE_APPROVE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case ADD_LEAVE_APPROVE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case UPDATE_LEAVE_APPROVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LEAVE_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case UPDATE_LEAVE_APPROVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case DELETE_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_LEAVE_RECORD:
      return {
        ...state,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};
export default LeaveReducer;
