import "./App.css";

import { Provider } from "react-redux";
import store from "./redux/store";
import setAuthorizationToken from "./redux/action/setAuthorization";
import jwtDecode from "jwt-decode";
import { signOut, setCurrentUser } from "./redux/action/AuthAction";
import React, { lazy, Suspense } from "react";

import { NotificationContainer } from "react-notifications";
import { DotLoaderOverlay } from "react-spinner-overlay";

const OtherComponent = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./AppRouter")), 500);
  });
});

function App() {
  if (
    localStorage.token !== null &&
    localStorage.token !== undefined &&
    localStorage.token !== ""
  ) {
    setAuthorizationToken(localStorage.token);
    let token = jwtDecode(localStorage.token);

    if (token !== null || token !== undefined || token !== "") {
      store.dispatch(setCurrentUser(token));
    } else {
      store.dispatch(signOut());
    }
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<DotLoaderOverlay />}>
        <OtherComponent />
        <NotificationContainer />
      </Suspense>
    </Provider>
  );
}

export default App;
