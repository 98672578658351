import {
  ADD_HOLIDAYLIST_FAIL,
  ADD_HOLIDAYLIST_SUCCESS,
  ADD_HOLIDAYLIST_REQUEST,
  DELETE_HOLIDAYLIST_REQUEST,
  DELETE_HOLIDAYLIST_SUCCESS,
  GETDETAIL_HOLIDAYLIST_REQUEST,
  GETDETAIL_HOLIDAYLIST_FAIL,
  GETDETAIL_HOLIDAYLIST_SUCCESS,
  UPDATE_HOLIDAYLIST_FAIL,
  UPDATE_HOLIDAYLIST_SUCCESS,
  UPDATE_HOLIDAYLIST_REQUEST,
  DELETE_HOLIDAYLIST_FAIL,
  GETALL_HOLIDAYLIST_FAIL,
  GETALL_HOLIDAYLIST_SUCCESS,
  GETALL_HOLIDAYLIST_REQUEST,
  CLEAR_HOLIDAY_RECORD,
} from "../constant/HolidayListConstant";

const initialState = {
  loading: false,
  action: "Holiday",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const HolidayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_HOLIDAYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_HOLIDAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_HOLIDAYLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GETDETAIL_HOLIDAYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETDETAIL_HOLIDAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GETDETAIL_HOLIDAYLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case ADD_HOLIDAYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_HOLIDAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case ADD_HOLIDAYLIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case UPDATE_HOLIDAYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_HOLIDAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case UPDATE_HOLIDAYLIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case DELETE_HOLIDAYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HOLIDAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_HOLIDAYLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_HOLIDAY_RECORD:
      return {
        ...state,
        loading: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default HolidayListReducer;
