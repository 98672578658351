// constant files

export const ADD_GENDER_REQUEST = "ADD_GENDER_REQUEST";
export const ADD_GENDER_SUCCESS = "ADD_GENDER_SUCCESS";
export const ADD_GENDER_FAIL = "ADD_GENDER_FAIL";

export const UPDATE_GENDER_REQUEST = "UPDATE_GENDER_REQUEST";
export const UPDATE_GENDER_SUCCESS = "UPDATE_GENDER_SUCCESS";
export const UPDATE_GENDER_FAIL = "UPDATE_GENDER_FAIL";

export const DELETE_GENDER_REQUEST = "DELETE_GENDER_REQUEST";
export const DELETE_GENDER_SUCCESS = "DELETE_GENDER_SUCCESS";
export const DELETE_GENDER_FAIL = "DELETE_GENDER_FAIL";

export const GETALL_GENDER_REQUEST = "GETALL_GENDER_REQUEST";
export const GETALL_GENDER_SUCCESS = "GETALL_GENDER_SUCCESS";
export const GETALL_GENDER_FAIL = "GETALL_GENDER_FAIL";

export const GETDETAIL_GENDER_REQUEST = "GETDETAIL_GENDER_REQUEST";
export const GETDETAIL_GENDER_SUCCESS = "GETDETAIL_GENDER_SUCCESS";
export const GETDETAIL_GENDER_FAIL = "GETDETAIL_GENDER_FAIL";

export const CLEAR_GENDER_RECORD = "CLEAR_GENDER_RECORD";
