import {
  GET_ALL_DESIGNATION_FAIL,
  GET_ALL_DESIGNATION_REQUEST,
  GET_ALL_DESIGNATION_SUCCESS,
  GET_SINGLE_DESIGNATION_FAIL,
  GET_SINGLE_DESIGNATION_REQUEST,
  GET_SINGLE_DESIGNATION_SUCCESS,
  ADD_DESIGNATION_FAIL,
  ADD_DESIGNATION_REQUEST,
  ADD_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAIL,
  UPDATE_DESIGNATION_REQUEST,
  UPDATE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_REQUEST,
  DELETE_DESIGNATION_FAIL,
  CLEAR_DESIGNATION_RECORD,
} from "../constant/DesignationConstant";
const initialState = {
  loading: false,
  action: "Designation",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const DesignationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GET_ALL_DESIGNATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GET_SINGLE_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_DESIGNATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case ADD_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case ADD_DESIGNATION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case UPDATE_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case UPDATE_DESIGNATION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case DELETE_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),

        message: action.message,
      };
    case DELETE_DESIGNATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,

        message: action.message,
      };
    case CLEAR_DESIGNATION_RECORD:
      return {
        ...state,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default DesignationReducer;
