import {
  GETALL_ADDRESS_FAIL,
  GETALL_ADDRESS_SUCCESS,
  GETALL_ADDRESS_REQUEST,
  ADD_ADDRESS_FAIL,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_REQUEST,
  UPDATE_EMPLOYEE_ADDRESS_FAIL,
  UPDATE_EMPLOYEE_ADDRESS_REQUEST,
  UPDATE_EMPLOYEE_ADDRESS_SUCCESS,
} from "../constant/EmployeeAddressConstant";

const initialState = {
  loading: false,
  action: "EmployeeAddress",
  result: [],
  singledata: [],
  message: "",
  error: "",
  success: false,
};

const EmployeeAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        // result: state.result.filter((items) =>
        //   items.id === action.payload.id
        //     ? Object.assign({}, items, action.payload)
        //     : items
        // ),

        message: action.payload,
      };
    case ADD_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: action.payload,
      };

    case UPDATE_EMPLOYEE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EMPLOYEE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        // result: state.result.map((items) =>
        //   items.id === action.payload.id
        //     ? Object.assign({}, items, action.payload)
        //     : items
        // ),
        message: action.message,
      };
    case UPDATE_EMPLOYEE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};

export default EmployeeAddressReducer;
