import setAuthorizationToken from "../utils/setAutorization";
import jwtDecode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_ERROR,
  SET_CURRENT_USER,
  SIGNOUT_USER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  SET_ERROR_FALSE,
  SET_SUCCESS_FALSE,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CREATE_REQUEST,
  USER_PASSWORD_CREATE_SUCCESS,
  USER_PASSWORD_CREATE_FAIL,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_FORGET_PASSWORD_FAIL,
  USER_EMAIL_PASSWORD_REQUEST,
  USER_EMAIL_PASSWORD_SUCCESS,
  USER_EMAIL_PASSWORD_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from "../constant/AuthConstant";
import axios from "axios";
import { Apis, Authapi } from "../Api/Api";
import { ApiError, ServerError } from "../Message/Message";
export const signinUser = (data) => {
  return async function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });
    await axios(`${Authapi}/api/Authenticate/login/`, {
      method: "POST",
      data: data,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        const token = response.data.token;
        if (token) {
          localStorage.setItem("token", JSON.stringify(token));
          setAuthorizationToken(token);
          let newData = jwtDecode(token);
          localStorage.setItem("userId", JSON.stringify(newData.UserId));
          dispatch(setCurrentUser(jwtDecode(token)));
          dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: false,
            isLoggedIn: true,
            success: true,
          });
          // NotificationManager.success("Login Success");
        } else {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          NotificationManager.warning(
            "Please Check Your Company Name,UserName and password"
          );
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          NotificationManager.error(ApiError);
        } else {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          NotificationManager.warning(
            "Please Check Your Company Name,UserName and password"
          );
        }
        dispatch({
          type: SET_MESSAGE,
        });
      });
  };
};

export const signOut = () => {
  return function (dispatch) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    dispatch({
      type: SIGNOUT_USER,
      payload: false,
    });
    dispatch({
      type: CLEAR_MESSAGE,
    });
  };
};
// SET CURRENT USER ACTION
export const setCurrentUser = (result) => {
  return {
    type: SET_CURRENT_USER,
    payload: result,
  };
};

// Register User
export const Createregister = (formData) => {
  return function (dispatch) {
    dispatch({
      type: USER_REGISTER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${Apis}/api/Companies/create`,
      method: "POST",
      data: formData,

      headers: {
        "Content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        if (res.data.Status === false) {
          let msg = "";
          msg = Object.values(res.data.Message.ErrorMessage)
            .map((err) => err[0])
            .join(", ");
          dispatch({
            type: USER_REGISTER_FAIL,
            msg,
          });
          NotificationManager.warning(msg);
        } else {
          dispatch(CreateregisterPre(res.data));
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: USER_REGISTER_FAIL,
            payload: false,
            error: error,
            isSignUp: error.success,
            success: false,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: USER_REGISTER_FAIL,
            payload: false,
            error: error,
            isSignUp: error.success,
            success: false,
          });
          NotificationManager.error(ApiError);
        } else {
          dispatch({
            type: USER_REGISTER_FAIL,
            payload: false,
            error: error,
            isSignUp: error.success,
            success: false,
          });
          NotificationManager.error("Company registration failed");
        }
      });
  };
};

export const CreateregisterPre = (data) => {
  NotificationManager.success(data.message.responseMessage);
  return {
    type: USER_REGISTER_SUCCESS,
    isSignUp: data.success,
    result: data,
  };
};

export const Setsuccessfalse = () => {
  return {
    type: SET_SUCCESS_FALSE,
  };
};
export const Seterrorfalse = () => {
  return {
    type: SET_ERROR_FALSE,
  };
};

export const changePassword = (data, navigate) => {
  return async (dispatch) => {
    dispatch({ type: USER_PASSWORD_CHANGE_REQUEST, payload: true });
    const token = JSON.parse(localStorage.getItem("token"))
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    await axios(`${Authapi}/api/Authenticate/changepassword/`, {
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const message =
          (response.data &&
            response.data.message &&
            response.data.message.responseMessage) ||
          response.message ||
          response.toString();
        dispatch({
          type: USER_PASSWORD_CHANGE_SUCCESS,
          payload:
            (response.data &&
              response.data.message &&
              response.data.message.responseMessage) ||
            response.message ||
            response.toString(),
          success: true,
        });
        NotificationManager.success(message);
        navigate("/admin/dashboard");
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: USER_PASSWORD_CHANGE_FAIL,
            success: false,
            payload: error.message,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: USER_PASSWORD_CHANGE_FAIL,
            success: false,
            payload: error.message,
          });
          NotificationManager.error(ApiError);
        } else {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message.responseMessage) ||
            error.message ||
            error.toString();
          dispatch({
            type: USER_PASSWORD_CHANGE_FAIL,
            success: false,
            payload:
              (error.response &&
                error.response.data &&
                error.response.data.message.responseMessage) ||
              error.message ||
              error.toString(),
          });
          NotificationManager.error(message);
        }
      });
  };
};

export const createPassword = (userId, password, confirmPassword, navigate) => {
  return function (dispatch) {
    dispatch({
      type: USER_PASSWORD_CREATE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${Authapi}/api/Authenticate/Create-password-admin/`,
      method: "POST",
      data: { userId, password, confirmPassword },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        if (res.data.Status === false) {
          const msgs = res.data.message.responseMessage;
          dispatch({
            type: USER_PASSWORD_CREATE_FAIL,
            msgs,
          });
          NotificationManager.warning(msgs);
        } else {
          dispatch(createPasswordPre(res.data));
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: USER_PASSWORD_CREATE_FAIL,
            payload: false,
            error: error,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: USER_PASSWORD_CREATE_FAIL,
            payload: false,
            error: error,
          });
          NotificationManager.error(ApiError);
        } else {
          const messagetoken =
            (error.response &&
              error.response.data &&
              error.response.data.message.responseMessage) ||
            error.message ||
            error.toString();
          dispatch({
            type: USER_PASSWORD_CREATE_FAIL,
            payload: false,
            error: error,
          });
          NotificationManager.warning(messagetoken);
        }
      });
  };
};
export const createPasswordPre = (data) => {
  const msg = data.message.responseMessage;
  NotificationManager.success(msg);
  return {
    type: USER_PASSWORD_CREATE_SUCCESS,
    result: data,
    payload: false,
  };
};

export const forgetPassword = (userId, token, password, confirmPassword) => {
  return function (dispatch) {
    dispatch({
      type: USER_FORGET_PASSWORD_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${Authapi}/api/Authenticate/forget-password-admin/`,
      method: "POST",
      data: { userId, token, password, confirmPassword },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(forgetPasswordPre(res.data));
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: USER_FORGET_PASSWORD_FAIL,
            payload: false,
            error: error,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: USER_FORGET_PASSWORD_FAIL,
            payload: false,
            error: error,
          });
          NotificationManager.error(ApiError);
        } else {
          dispatch({
            type: USER_FORGET_PASSWORD_FAIL,
            payload: false,
            error: error,
          });
        }
      });
  };
};
export const forgetPasswordPre = (data) => {
  NotificationManager.success("Password Change Successfully");
  return {
    type: USER_FORGET_PASSWORD_SUCCESS,
    result: data,
    payload: false,
  };
};

export const createemailconfirm = (data) => {
  return async (dispatch) => {
    dispatch({ type: USER_EMAIL_PASSWORD_REQUEST, payload: true });
    const token = JSON.parse(localStorage.getItem("token"))
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    await axios(`${Authapi}/api/EmailVerification/emailverification/`, {
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const message =
          (response.data &&
            response.data.message &&
            response.data.message.responseMessage) ||
          response.message ||
          response.toString();
        dispatch({
          type: USER_EMAIL_PASSWORD_SUCCESS,
          payload:
            (response.data &&
              response.data.message &&
              response.data.message.responseMessage) ||
            response.message ||
            response.toString(),
          success: true,
        });
        NotificationManager.success(message);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: USER_EMAIL_PASSWORD_FAIL,
            success: false,
            payload: error.message,
          });
          NotificationManager.error(ServerError);
        } else if (error.response.status === 403) {
          dispatch({
            type: USER_EMAIL_PASSWORD_FAIL,
            success: false,
            payload: error.message,
          });
          NotificationManager.error(ApiError);
        } else {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message.responseMessage) ||
            error.message ||
            error.toString();
          dispatch({
            type: USER_EMAIL_PASSWORD_FAIL,
            success: false,
            payload:
              (error.response &&
                error.response.data &&
                error.response.data.message.responseMessage) ||
              error.message ||
              error.toString(),
          });
          NotificationManager.warning(message);
        }
      });
  };
};
