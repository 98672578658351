import {
  ADD_LOCATION_FAIL,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_REQUEST,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  GETDETAIL_LOCATION_REQUEST,
  GETDETAIL_LOCATION_FAIL,
  GETDETAIL_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  DELETE_LOCATION_FAIL,
  GETALL_LOCATION_FAIL,
  GETALL_LOCATION_SUCCESS,
  GETALL_LOCATION_REQUEST,
  LOCATION_CHANGE_STATUS_FAIL,
  LOCATION_CHANGE_STATUS_REQUEST,
  LOCATION_CHANGE_STATUS_SUCCESS,
} from "../constant/LocationConstant";

const initialState = {
  loading: false,
  action: "Location",
  result: [],
  response: {},
  singledata: [],
  message: "",
  error: "",
};

const LocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_LOCATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GETALL_LOCATION_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        message: action.message,
      };
    case GETALL_LOCATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        result: state.result.filter(
          (item) => item.id !== action.result
        ),
        response: action.result.message,
        loading: action.payload,
        message: action.message,
      };
    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    case ADD_LOCATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        response: action.result.message,
        loading: action.payload,
        message: action.message,
      };
    case ADD_LOCATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    case UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.id ? action.result : item
        ),
        response: action.result.message,
        singledata: [],
        loading: action.payload,

        message: action.message,
      };
    case UPDATE_LOCATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    case LOCATION_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case LOCATION_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.message,
        singledata: [],
        loading: action.payload,

        message: action.message,
      };
    case LOCATION_CHANGE_STATUS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    case GETDETAIL_LOCATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GETDETAIL_LOCATION_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        message: action.message,
      };
    case GETDETAIL_LOCATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        message: action.message,
      };

    default:
      return state;
  }
};

export default LocationReducer;
