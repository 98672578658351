import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducer/index";
const middlewares = [thunk];
const store = configureStore({
  reducer: rootReducer,
  middlewares: composeWithDevTools(applyMiddleware(...middlewares)),
});

export default store;
