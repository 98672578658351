export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";

export const GET_SINGLE_PROJECT_REQUEST = "GET_SINGLE_PROJECT_REQUEST";
export const GET_SINGLE_PROJECT_SUCCESS = "GET_SINGLE_PROJECT_SUCCESS";
export const GET_SINGLE_PROJECT_FAIL = "GET_SINGLE_PROJECT_FAIL";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "CDELETEPROJECT_FAIL";

export const CLEAR_PROJECT_RECORD = "CLEAR_PROJECT_RECORD";
