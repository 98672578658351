// constant files

export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL";

export const GETALL_ADDRESS_REQUEST = "GETALL_ADDRESS_REQUEST";
export const GETALL_ADDRESS_SUCCESS = "GETALL_ADDRESS_SUCCESS";
export const GETALL_ADDRESS_FAIL = "GETALL_ADDRESS_FAIL";

export const GETDETAIL_ADDRESS_REQUEST = "GETDETAIL_ADDRESS_REQUEST";
export const GETDETAIL_ADDRESS_SUCCESS = "GETDETAIL_ADDRESS_SUCCESS";
export const GETDETAIL_ADDRESS_FAIL = "GETDETAIL_ADDRESS_FAIL";
export const CLEAR_ADDRESS_RECORDS = "CLEAR_ADDRESS_RECORDS";
