export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAIL = "GET_CLIENT_FAIL";

export const GET_TEAM_BY_CLIENT_REQUEST = "GET_TEAM_BY_CLIENT_REQUEST";
export const GET_TEAM_BY_CLIENT_SUCCESS = "GET_TEAM_BY_CLIENT_SUCCESS";
export const GET_TEAM_BY_CLIENT_FAIL = "GET_TEAM_BY_CLIENT_FAIL";

export const GET_SINGLE_CLIENT_REQUEST = "GET_SINGLE_CLIENT_REQUEST";
export const GET_SINGLE_CLIENT_SUCCESS = "GET_SINGLE_CLIENT_SUCCESS";
export const GET_SINGLE_CLIENT_FAIL = "GET_SINGLE_CLIENT_FAIL";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "CDELETECLIENT_FAIL";

export const CLEAR_CLIENT_RECORD = "CLEAR_CLIENT_RECORD";
