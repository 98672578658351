// constant files

export const ADD_LEAVE_STATUS_REQUEST = "ADD_LEAVE_STATUS_REQUEST";
export const ADD_LEAVE_STATUS_SUCCESS = "ADD_LEAVE_STATUS_SUCCESS";
export const ADD_LEAVE_STATUS_FAIL = "ADD_LEAVE_STATUS_FAIL";

export const UPDATE_LEAVE_STATUS_REQUEST = "UPDATE_LEAVE_STATUS_REQUEST";
export const UPDATE_LEAVE_STATUS_SUCCESS = "UPDATE_LEAVE_STATUS_SUCCESS";
export const UPDATE_LEAVE_STATUS_FAIL = "UPDATE_LEAVE_STATUS_FAIL";

export const DELETE_LEAVE_STATUS_REQUEST = "DELETE_LEAVE_STATUS_REQUEST";
export const DELETE_LEAVE_STATUS_SUCCESS = "DELETE_LEAVE_STATUS_SUCCESS";
export const DELETE_LEAVE_STATUS_FAIL = "DELETE_LEAVE_STATUS_FAIL";

export const GETALL_LEAVE_STATUS_REQUEST = "GETALL_LEAVE_STATUS_REQUEST";
export const GETALL_LEAVE_STATUS_SUCCESS = "GETALL_LEAVE_STATUS_SUCCESS";
export const GETALL_LEAVE_STATUS_FAIL = "GETALL_LEAVE_STATUS_FAIL";

export const GETDETAIL_LEAVE_STATUS_REQUEST = "GETDETAIL_LEAVE_STATUS_REQUEST";
export const GETDETAIL_LEAVE_STATUS_SUCCESS = "GETDETAIL_LEAVE_STATUS_SUCCESS";
export const GETDETAIL_LEAVE_STATUS_FAIL = "GETDETAIL_LEAVE_STATUS_FAIL";

export const CLEAR_LEAVE_STATUS_RECORD = "CLEAR_LEAVE_STATUS_RECORD";
