// constant files

export const ADD_STATE_REQUEST = "ADD_STATE_REQUEST";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_FAIL = "ADD_STATE_FAIL";

export const UPDATE_STATE_REQUEST = "UPDATE_STATE_REQUEST";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL";

export const DELETE_STATE_REQUEST = "DELETE_STATE_REQUEST";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL";

export const GETALL_STATE_REQUEST = "GETALL_STATE_REQUEST";
export const GETALL_STATE_SUCCESS = "GETALL_STATE_SUCCESS";
export const GETALL_STATE_FAIL = "GETALL_STATE_FAIL";

export const GETDETAIL_STATE_REQUEST = "GETDETAIL_STATE_REQUEST";
export const GETDETAIL_STATE_SUCCESS = "GETDETAIL_STATE_SUCCESS";
export const GETDETAIL_STATE_FAIL = "GETDETAIL_STATE_FAIL";

