
export const ADD_LEAVE_TYPE_REQUEST = "ADD_LEAVE_TYPE_REQUEST";
export const ADD_LEAVE_TYPE_SUCCESS = "ADD_LEAVE_TYPE_SUCCESS";
export const ADD_LEAVE_TYPE_FAIL = "ADD_LEAVE_TYPE_FAIL";

export const UPDATE_LEAVE_TYPE_REQUEST = "UPDATE_LEAVE_TYPE_REQUEST";
export const UPDATE_LEAVE_TYPE_SUCCESS = "UPDATE_LEAVE_TYPE_SUCCESS";
export const UPDATE_LEAVE_TYPE_FAIL = "UPDATE_LEAVE_TYPE_FAIL";

export const DELETE_LEAVE_TYPE_REQUEST = "DELETE_LEAVE_TYPE_REQUEST";
export const DELETE_LEAVE_TYPE_SUCCESS = "DELETE_LEAVE_TYPE_SUCCESS";
export const DELETE_LEAVE_TYPE_FAIL = "DELETE_LEAVE_TYPE_FAIL";

export const GETALL_LEAVE_TYPE_REQUEST = "GETALL_LEAVE_TYPE_REQUEST";
export const GETALL_LEAVE_TYPE_SUCCESS = "GETALL_LEAVE_TYPE_SUCCESS";
export const GETALL_LEAVE_TYPE_FAIL = "GETALL_LEAVE_TYPE_FAIL";

export const GETDETAIL_LEAVE_TYPE_REQUEST = "GETDETAIL_LEAVE_TYPE_REQUEST";
export const GETDETAIL_LEAVE_TYPE_SUCCESS = "GETDETAIL_LEAVE_TYPE_SUCCESS";
export const GETDETAIL_LEAVE_TYPE_FAIL = "GETDETAIL_LEAVE_TYPE_FAIL";

export const CLEAR_LEAVE_TYPE_RECORD = "CLEAR_LEAVE_TYPE_RECORD";
