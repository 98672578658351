export const GET_ALL_DEPARTMENT_REQUEST = "GET_ALL_DEPARTMENT_REQUEST";
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS";
export const GET_ALL_DEPARTMENT_FAIL = "GET_ALL_DEPARTMENT_FAIL";

export const GET_SINGLE_DEPARTMENT_REQUEST = "GET_SINGLE_DEPARTMENT_REQUEST";
export const GET_SINGLE_DEPARTMENT_SUCCESS = "GET_SINGLE_DEPARTMENT_SUCCESS";
export const GET_SINGLE_DEPARTMENT_FAIL = "GET_SINGLE_DEPARTMENT_FAIL";

export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL";

export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL";

export const CLEAR_DEPARTMENT_RECORD = "CLEAR_DEPARTMENT_RECORD";
