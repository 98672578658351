import {
  GETALL_ROLE_FAIL,
  GETALL_ROLE_REQUEST,
  GETALL_ROLE_SUCCESS,
  GETDETAIL_ROLE_FAIL,
  GETDETAIL_ROLE_REQUEST,
  GETDETAIL_ROLE_SUCCESS,  
} from "../constant/RoleConstant";

const initialState = {
  loading: false,
  action: "Role",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        message: action.message,
      };
    case GETALL_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GETDETAIL_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETDETAIL_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GETDETAIL_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};

export default RoleReducer;
