import {
  GET_ALL_REPORTING_EMPLOYEE_FAIL,
  GET_ALL_REPORTING_EMPLOYEE_REQUEST,
  GET_ALL_REPORTING_EMPLOYEE_SUCCESS,
  GET_DETAIL_REPORTING_EMPLOYEE_FAIL,
  GET_DETAIL_REPORTING_EMPLOYEE_REQUEST,
  GET_DETAIL_REPORTING_EMPLOYEE_SUCCESS,
} from "../constant/ReportingEmployeeConstant";

const initialState = {
  loading: false,
  action: "Reporting_Employee",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const ReportingEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REPORTING_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_REPORTING_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        message: action.message,
      };
    case GET_ALL_REPORTING_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GET_DETAIL_REPORTING_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DETAIL_REPORTING_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_DETAIL_REPORTING_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};

export default ReportingEmployeeReducer;
