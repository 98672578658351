export const GETALL_EMPLOYEE_DOCUMENTS_REQUEST =
  "GETALL_EMPLOYEE_DOCUMENTS_REQUEST";
export const GETALL_EMPLOYEE_DOCUMENTS_SUCCESS =
  "GETALL_EMPLOYEE_DOCUMENTS_SUCCESS";
export const GETALL_EMPLOYEE_DOCUMENTS_FAIL =
  "GETALL_EMPLOYEE_DOCUMENTS_FAIL";

export const ADD_EMPLOYEE_DOCUMENTS_REQUEST = "ADD_EMPLOYEE_DOCUMENTS_REQUEST";
export const ADD_EMPLOYEE_DOCUMENTS_SUCCESS = "ADD_EMPLOYEE_DOCUMENTS_SUCCESS";
export const ADD_EMPLOYEE_DOCUMENTS_FAIL = "ADD_EMPLOYEE_DOCUMENTS_FAIL";

export const UPDATE_EMPLOYEE_DOCUMENTS_REQUEST =
  "UPDATE_EMPLOYEE_DOCUMENTS_REQUEST";
export const UPDATE_EMPLOYEE_DOCUMENTS_SUCCESS =
  "UPDATE_EMPLOYEE_DOCUMENTS_SUCCESS";
export const UPDATE_EMPLOYEE_DOCUMENTS_FAIL = "UPDATE_EMPLOYEE_DOCUMENTS_FAIL";
