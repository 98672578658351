// constant files
export const GETALL_TEAM_REQUEST = "GETALL_TEAM_REQUEST";
export const GETALL_TEAM_SUCCESS = "GETALL_TEAM_SUCCESS";
export const GETALL_TEAM_FAIL = "GETALL_TEAM_FAIL";

export const GETDETAIL_TEAM_REQUEST = "GETDETAIL_TEAM_REQUEST";
export const GETDETAIL_TEAM_SUCCESS = "GETDETAIL_TEAM_SUCCESS";
export const GETDETAIL_TEAM_FAIL = "GETDETAIL_TEAM_FAIL";

export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";


export const CLEAR_TEAM_RECORD = "CLEAR_TEAM_RECORD";


