export const GET_PROJECT_STATUS_REQUEST = "GET_PROJECT_STATUS_REQUEST";
export const GET_PROJECT_STATUS_SUCCESS = "GET_PROJECT_STATUS_SUCCESS";
export const GET_PROJECT_STATUS_FAIL = "GET_PROJECT_STATUS_FAIL";

export const GET_SINGLE_PROJECT_STATUS_REQUEST = "GET_SINGLE_PROJECT_STATUS_REQUEST";
export const GET_SINGLE_PROJECT_STATUS_SUCCESS = "GET_SINGLE_PROJECT_STATUS_SUCCESS";
export const GET_SINGLE_PROJECT_STATUS_FAIL = "GET_SINGLE_PROJECT_STATUS_FAIL";

export const CREATE_PROJECT_STATUS_REQUEST = "CREATE_PROJECT_STATUS_REQUEST";
export const CREATE_PROJECT_STATUS_SUCCESS = "CREATE_PROJECT_STATUS_SUCCESS";
export const CREATE_PROJECT_STATUS_FAIL = "CREATE_PROJECT_STATUS_FAIL";

export const UPDATE_PROJECT_STATUS_REQUEST = "UPDATE_PROJECT_STATUS_REQUEST";
export const UPDATE_PROJECT_STATUS_SUCCESS = "UPDATE_PROJECT_STATUS_SUCCESS";
export const UPDATE_PROJECT_STATUS_FAIL = "UPDATE_PROJECT_STATUS_FAIL";

export const DELETE_PROJECT_STATUS_REQUEST = "DELETE_PROJECT_STATUS_REQUEST";
export const DELETE_PROJECT_STATUS_SUCCESS = "DELETE_PROJECT_STATUS_SUCCESS";
export const DELETE_PROJECT_STATUS_FAIL = "CDELETEPROJECT_STATUS_FAIL";

export const CLEAR_PROJECT_STATUS_RECORD = "CLEAR_PROJECT_STATUS_RECORD";
