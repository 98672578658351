
export const GETALL_EMPLOYEE_CONTRACTUAL_REQUEST = "GETALL_EMPLOYEE_CONTRACTUAL_REQUEST";
export const GETALL_EMPLOYEE_CONTRACTUAL_SUCCESS = "GETALL_EMPLOYEE_CONTRACTUAL_SUCCESS";
export const GETALL_EMPLOYEE_CONTRACTUAL_FAIL = "GETALL_EMPLOYEE_CONTRACTUAL_FAIL";

export const ADD_EMPLOYEE_CONTRACTUAL_REQUEST = "ADD_EMPLOYEE_CONTRACTUAL_REQUEST";
export const ADD_EMPLOYEE_CONTRACTUAL_SUCCESS = "ADD_EMPLOYEE_CONTRACTUAL_SUCCESS";
export const ADD_EMPLOYEE_CONTRACTUAL_FAIL = "ADD_EMPLOYEE_CONTRACTUAL_FAIL";

export const UPDATE_EMPLOYEE_CONTRACTUAL_REQUEST = "UPDATE_EMPLOYEE_CONTRACTUAL_REQUEST";
export const UPDATE_EMPLOYEE_CONTRACTUAL_SUCCESS = "UPDATE_EMPLOYEE_CONTRACTUAL_SUCCESS";
export const UPDATE_EMPLOYEE_CONTRACTUAL_FAIL = "UPDATE_EMPLOYEE_CONTRACTUAL_FAIL";
