export const GET_ALL_REPORTING_EMPLOYEE_REQUEST =
  "GET_ALL_REPORTING_EMPLOYEE_REQUEST";
export const GET_ALL_REPORTING_EMPLOYEE_SUCCESS =
  "GET_ALL_REPORTING_EMPLOYEE_SUCCESS";
export const GET_ALL_REPORTING_EMPLOYEE_FAIL =
  "GET_ALL_REPORTING_EMPLOYEE_FAIL";

export const GET_DETAIL_REPORTING_EMPLOYEE_REQUEST =
  "GET_DETAIL_REPORTING_EMPLOYEE_REQUEST";
export const GET_DETAIL_REPORTING_EMPLOYEE_SUCCESS =
  "GET_DETAIL_REPORTING_EMPLOYEE_SUCCESS";
export const GET_DETAIL_REPORTING_EMPLOYEE_FAIL =
  "GET_DETAIL_REPORTING_EMPLOYEE_FAIL";
