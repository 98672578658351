// constant files

export const ADD_MARITIAL_STATUS_REQUEST = "ADD_MARITIAL_STATUS_REQUEST";
export const ADD_MARITIAL_STATUS_SUCCESS = "ADD_MARITIAL_STATUS_SUCCESS";
export const ADD_MARITIAL_STATUS_FAIL = "ADD_MARITIAL_STATUS_FAIL";

export const UPDATE_MARITIAL_STATUS_REQUEST = "UPDATE_MARITIAL_STATUS_REQUEST";
export const UPDATE_MARITIAL_STATUS_SUCCESS = "UPDATE_MARITIAL_STATUS_SUCCESS";
export const UPDATE_MARITIAL_STATUS_FAIL = "UPDATE_MARITIAL_STATUS_FAIL";

export const DELETE_MARITIAL_STATUS_REQUEST = "DELETE_MARITIAL_STATUS_REQUEST";
export const DELETE_MARITIAL_STATUS_SUCCESS = "DELETE_MARITIAL_STATUS_SUCCESS";
export const DELETE_MARITIAL_STATUS_FAIL = "DELETE_MARITIAL_STATUS_FAIL";

export const GETALL_MARITIAL_STATUS_REQUEST = "GETALL_MARITIAL_STATUS_REQUEST";
export const GETALL_MARITIAL_STATUS_SUCCESS = "GETALL_MARITIAL_STATUS_SUCCESS";
export const GETALL_MARITIAL_STATUS_FAIL = "GETALL_MARITIAL_STATUS_FAIL";

export const GETDETAIL_MARITIAL_STATUS_REQUEST =
  "GETDETAIL_MARITIAL_STATUS_REQUEST";
export const GETDETAIL_MARITIAL_STATUS_SUCCESS =
  "GETDETAIL_MARITIAL_STATUS_SUCCESS";
export const GETDETAIL_MARITIAL_STATUS_FAIL = "GETDETAIL_MARITIAL_STATUS_FAIL";
export const CLEAR_MATRIAL_STATUS_RECORD = "CLEAR_MATRIAL_STATUS_RECORD";
