export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const SET_SUCCESS_FALSE = "SET_SUCCESS_FALSE";
export const SET_ERROR_FALSE = "SET_ERROR_FALSE";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";

export const USER_PASSWORD_CREATE_REQUEST = "USER_PASSWORD_CREATE_REQUEST";
export const USER_PASSWORD_CREATE_SUCCESS = "USER_PASSWORD_CREATE_SUCCESS";
export const USER_PASSWORD_CREATE_FAIL = "USER_PASSWORD_CREATE_FAIL";

export const USER_FORGET_PASSWORD_REQUEST = "USER_FORGET_PASSWORD_REQUEST";
export const USER_FORGET_PASSWORD_SUCCESS = "USER_FORGET_PASSWORD_SUCCESS";
export const USER_FORGET_PASSWORD_FAIL = "USER_FORGET_PASSWORD_FAIL";

export const USER_EMAIL_PASSWORD_REQUEST = "USER_EMAIL_PASSWORD_REQUEST";
export const USER_EMAIL_PASSWORD_SUCCESS = "USER_EMAIL_PASSWORD_SUCCESS";
export const USER_EMAIL_PASSWORD_FAIL = "USER_EMAIL_PASSWORD_FAIL";
