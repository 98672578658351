// constant files

export const ADD_EMPLOYMENT_TYPE_REQUEST = "ADD_EMPLOYMENT_TYPE_REQUEST";
export const ADD_EMPLOYMENT_TYPE_SUCCESS = "ADD_EMPLOYMENT_TYPE_SUCCESS";
export const ADD_EMPLOYMENT_TYPE_FAIL = "ADD_EMPLOYMENT_TYPE_FAIL";

export const UPDATE_EMPLOYMENT_TYPE_REQUEST = "UPDATE_EMPLOYMENT_TYPE_REQUEST";
export const UPDATE_EMPLOYMENT_TYPE_SUCCESS = "UPDATE_EMPLOYMENT_TYPE_SUCCESS";
export const UPDATE_EMPLOYMENT_TYPE_FAIL = "UPDATE_EMPLOYMENT_TYPE_FAIL";

export const DELETE_EMPLOYMENT_TYPE_REQUEST = "DELETE_EMPLOYMENT_TYPE_REQUEST";
export const DELETE_EMPLOYMENT_TYPE_SUCCESS = "DELETE_EMPLOYMENT_TYPE_SUCCESS";
export const DELETE_EMPLOYMENT_TYPE_FAIL = "DELETE_EMPLOYMENT_TYPE_FAIL";

export const GETALL_EMPLOYMENT_TYPE_REQUEST = "GETALL_EMPLOYMENT_TYPE_REQUEST";
export const GETALL_EMPLOYMENT_TYPE_SUCCESS = "GETALL_EMPLOYMENT_TYPE_SUCCESS";
export const GETALL_EMPLOYMENT_TYPE_FAIL = "GETALL_EMPLOYMENT_TYPE_FAIL";

export const GETDETAIL_EMPLOYMENT_TYPE_REQUEST =
  "GETDETAIL_EMPLOYMENT_TYPE_REQUEST";
export const GETDETAIL_EMPLOYMENT_TYPE_SUCCESS =
  "GETDETAIL_EMPLOYMENT_TYPE_SUCCESS";
export const GETDETAIL_EMPLOYMENT_TYPE_FAIL = "GETDETAIL_EMPLOYMENT_TYPE_FAIL";
export const CLEAR_EMPTYPE_RECORD = "CLEAR_EMPTYPE_RECORD";
