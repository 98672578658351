export const GET_SINGLE_PROFILE_REQUEST = "GET_SINGLE_PROFILE_REQUEST";
export const GET_SINGLE_PROFILE_SUCCESS = "GET_SINGLE_PROFILE_SUCCESS";
export const GET_SINGLE_PROFILE_FAIL = "GET_SINGLE_PROFILE_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPDATE_COMPANY_PROFILE_REQUEST = "UPDATE_COMPANY_PROFILE_REQUEST";
export const UPDATE_COMPANY_PROFILE_SUCCESS = "UPDATE_COMPANY_PROFILE_SUCCESS";
export const UPDATE_COMPANY_PROFILE_FAIL = "UPDATE_COMPANY_PROFILE_FAIL";

export const CLEAR_PROFILE_RECORD = "CLEAR_PROFILE_RECORD";
