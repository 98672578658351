// constant files

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const GET_ALL_COMPANY_REQUEST = "GET_ALL_COMPANY_REQUEST";
export const GET_ALL_COMPANY_SUCCESS = "GET_ALL_COMPANY_SUCCESS";
export const GET_ALL_COMPANY_FAIL = "GET_ALL_COMPANY_FAIL";

export const GET_SINGLE_COMPANY_REQUEST = "GET_SINGLE_COMPANY_REQUEST";
export const GET_SINGLE_COMPANY_SUCCESS = "GET_SINGLE_COMPANY_SUCCESS";
export const GET_SINGLE_COMPANY_FAIL = "GET_SINGLE_COMPANY_FAIL";

export const COMPANY_CHANGE_STATUS_REQUEST = "COMPANY_CHANGE_STATUS_REQUEST";
export const COMPANY_CHANGE_STATUS_SUCCESS = "COMPANY_CHANGE_STATUS_SUCCESS";
export const COMPANY_CHANGE_STATUS_FAIL = "COMPANY_CHANGE_STATUS_FAIL";
