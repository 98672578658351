import {
  ADD_ADDRESS_FAIL,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_REQUEST,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  GETDETAIL_ADDRESS_REQUEST,
  GETDETAIL_ADDRESS_FAIL,
  GETDETAIL_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_REQUEST,
  DELETE_ADDRESS_FAIL,
  GETALL_ADDRESS_FAIL,
  GETALL_ADDRESS_SUCCESS,
  GETALL_ADDRESS_REQUEST,
  CLEAR_ADDRESS_RECORDS,
} from "../constant/AddressConstant";

const initialState = {
  loading: false,
  action: "Address",
  result: [],
  singledata: [],
  message: "",
  error: "",
  success: false,
};

const AddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };

    case GETDETAIL_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETDETAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.payload,
      };
    case GETDETAIL_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.payload,
      };

    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        success: true,
        message: action.payload,
      };
    case ADD_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: action.payload,
      };

    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
      };
    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: action.payload,
      };
    case DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.payload,
      };
    case DELETE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    case CLEAR_ADDRESS_RECORDS:
      return {
        ...state,
        loading: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default AddressReducer;
