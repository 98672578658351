// constant files

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL";

export const UPDATE_LOCATION_REQUEST = "UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";

export const GETALL_LOCATION_REQUEST = "GETALL_LOCATION_REQUEST";
export const GETALL_LOCATION_SUCCESS = "GETALL_LOCATION_SUCCESS";
export const GETALL_LOCATION_FAIL = "GETALL_LOCATION_FAIL";

export const GETDETAIL_LOCATION_REQUEST = "GETDETAIL_LOCATION_REQUEST";
export const GETDETAIL_LOCATION_SUCCESS = "GETDETAIL_LOCATION_SUCCESS";
export const GETDETAIL_LOCATION_FAIL = "GETDETAIL_LOCATION_FAIL";

export const LOCATION_CHANGE_STATUS_REQUEST =
  "LOCATION_CHANGE_STATUS_REQUEST";
export const LOCATION_CHANGE_STATUS_SUCCESS =
  "LOCATION_CHANGE_STATUS_SUCCESS";
export const LOCATION_CHANGE_STATUS_FAIL = "LOCATION_CHANGE_STATUS_FAIL";
