import {
  GETALL_EMPLOYEE_DOCUMENTS_FAIL,
  GETALL_EMPLOYEE_DOCUMENTS_SUCCESS,
  GETALL_EMPLOYEE_DOCUMENTS_REQUEST,
  ADD_EMPLOYEE_DOCUMENTS_REQUEST,
  ADD_EMPLOYEE_DOCUMENTS_SUCCESS,
  ADD_EMPLOYEE_DOCUMENTS_FAIL,
  UPDATE_EMPLOYEE_DOCUMENTS_REQUEST,
  UPDATE_EMPLOYEE_DOCUMENTS_SUCCESS,
  UPDATE_EMPLOYEE_DOCUMENTS_FAIL,
} from "../constant/EmployeeDocumentConstant";

const initialState = {
  loading: false,
  action: "Employee_Document",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const EmployeeDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_EMPLOYEE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        message: action.message,
      };
    case GETALL_EMPLOYEE_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    case ADD_EMPLOYEE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        success: true,
        message: action.payload,
      };
    case ADD_EMPLOYEE_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: action.payload,
      };

    case UPDATE_EMPLOYEE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.id ? action.result : item
        ),

        loading: false,

        msg: action.msg,
      };
    case UPDATE_EMPLOYEE_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default EmployeeDocumentReducer;
