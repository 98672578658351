import { combineReducers } from "redux";
import AddressReducer from "./AddressReducer";
import CompanyReducer from "./CompanyReducer";
import BankReducer from "./BankReducer";
import CountryReducer from "./CountryReducer";
import DepartmentReducer from "./DepartmentReducer";
import DesignationReducer from "./DesignationReducer";
import AuthReducer from "./AuthReducer";
import DocumentsTypeReducer from "./DocumentsTypeReducer";
import EmployeeFamilyTypeReducer from "./EmployeeFamilyTypeReducer";
import EmployeeQualificationTypeReducer from "./EmployeeQualificationTypeReducer";
import EmploymentTypeReducer from "./EmploymentTypeReducer";
import GenderReducer from "./GenderReducer";
import HolidayListReducer from "./HolidayListReducer";
import LeaveFinancialYearReducer from "./LeaveFinancialYearReducer";
import LeaveStatusReducer from "./LeaveStatusReducer";
import LeaveTypeReducer from "./LeaveTypeReducer";
import LocationReducer from "./LocationReducer";
import MaritialStatusReducer from "./MaritialStatusReducer";
import TeamReducer from "./TeamReducer";
import StateReducer from "./StateReducer";
import EmployeeReducer from "./EmployeeReducer";
import CityReducer from "./CityReducer";
import ProfileReducer from "./ProfileReducer";
import UniversityReducer from "./UniversityReducer";
import EmployeeAddressReducer from "./EmployeeAddressReducer";
import EmployeecontractualReducer from "./EmployeecontractualReducer";
import EmployeeDocumentReducer from "./EmployeeDocumentReducer";
import RoleReducer from "./RoleReducer";
import ReportingEmployeeReducer from "./ReportingEmployeeReducer";
import LeaveReducer from "./LeaveReducer";
import ProjectReducer from "./ProjectReducer";
import ClientReducer from "./ClientReducer";
import ActivityReducer from "./ActivityReducer";
import ScopeReducer from "./ScopeReducer";
import ProjectStatusReducer from "./ProjectStatusReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Address: AddressReducer,
  Company: CompanyReducer,
  Bank: BankReducer,
  Country: CountryReducer,
  Department: DepartmentReducer,
  Designation: DesignationReducer,
  Document: DocumentsTypeReducer,
  EmployeeFamilyType: EmployeeFamilyTypeReducer,
  EmployeeQualificationType: EmployeeQualificationTypeReducer,
  EmploymentType: EmploymentTypeReducer,
  Gender: GenderReducer,
  HolidayList: HolidayListReducer,
  LeaveFinancialYear: LeaveFinancialYearReducer,
  LeaveStatus: LeaveStatusReducer,
  LeaveType: LeaveTypeReducer,
  Location: LocationReducer,
  MaritialStatus: MaritialStatusReducer,
  State: StateReducer,
  Team: TeamReducer,
  Employee: EmployeeReducer,
  City: CityReducer,
  Profile: ProfileReducer,
  University: UniversityReducer,
  EmployeeAddress: EmployeeAddressReducer,
  Employeecontractual: EmployeecontractualReducer,
  EmployeeDocument: EmployeeDocumentReducer,
  Role: RoleReducer,
  ReportingEmployee: ReportingEmployeeReducer,
  Leave: LeaveReducer,
  Project: ProjectReducer,
  Client: ClientReducer,
  Activity: ActivityReducer,
  Scope: ScopeReducer,
  ProjectStatus: ProjectStatusReducer,
});

export default rootReducer;
