export const GET_SCOPE_REQUEST = "GET_SCOPE_REQUEST";
export const GET_SCOPE_SUCCESS = "GET_SCOPE_SUCCESS";
export const GET_SCOPE_FAIL = "GET_SCOPE_FAIL";

export const GET_SINGLE_SCOPE_REQUEST = "GET_SINGLE_SCOPE_REQUEST";
export const GET_SINGLE_SCOPE_SUCCESS = "GET_SINGLE_SCOPE_SUCCESS";
export const GET_SINGLE_SCOPE_FAIL = "GET_SINGLE_SCOPE_FAIL";

export const CREATE_SCOPE_REQUEST = "CREATE_SCOPE_REQUEST";
export const CREATE_SCOPE_SUCCESS = "CREATE_SCOPE_SUCCESS";
export const CREATE_SCOPE_FAIL = "CREATE_SCOPE_FAIL";

export const UPDATE_SCOPE_REQUEST = "UPDATE_SCOPE_REQUEST";
export const UPDATE_SCOPE_SUCCESS = "UPDATE_SCOPE_SUCCESS";
export const UPDATE_SCOPE_FAIL = "UPDATE_SCOPE_FAIL";

export const DELETE_SCOPE_REQUEST = "DELETE_SCOPE_REQUEST";
export const DELETE_SCOPE_SUCCESS = "DELETE_SCOPE_SUCCESS";
export const DELETE_SCOPE_FAIL = "CDELETESCOPE_FAIL";

export const CLEAR_SCOPE_RECORD = "CLEAR_SCOPE_RECORD";
