// constant files

export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAIL = "ADD_CITY_FAIL";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAIL = "DELETE_CITY_FAIL";

export const GET_ALL_CITY_REQUEST = "GET_ALL_CITY_REQUEST";
export const GET_ALL_CITY_SUCCESS = "GET_ALL_CITY_SUCCESS";
export const GET_ALL_CITY_FAIL = "GET_ALL_CITY_FAIL";

export const GET_SINGLE_CITY_REQUEST = "GET_SINGLE_CITY_REQUEST";
export const GET_SINGLE_CITY_SUCCESS = "GET_SINGLE_CITY_SUCCESS";
export const GET_SINGLE_CITY_FAIL = "GET_SINGLE_CITY_FAIL";
