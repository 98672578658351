export const GETALL_UNIVERSITY_REQUEST = "GETALL_UNIVERSITY_REQUEST";
export const GETALL_UNIVERSITY_SUCCESS = "GETALL_UNIVERSITY_SUCCESS";
export const GETALL_UNIVERSITY_FAIL = "GETALL_UNIVERSITY_FAIL";

export const GET_UNIVERSITY_REQUEST = "GET_UNIVERSITY_REQUEST";
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS";
export const GET_UNIVERSITY_FAIL = "GET_UNIVERSITY_FAIL";

export const ADD_UNIVERSITY_REQUEST = "ADD_UNIVERSITY_REQUEST";
export const ADD_UNIVERSITY_SUCCESS = "ADD_UNIVERSITY_SUCCESS";
export const ADD_UNIVERSITY_FAIL = "ADD_UNIVERSITY_FAIL";

export const UPDATE_UNIVERSITY_REQUEST = "UPDATE_UNIVERSITY_REQUEST";
export const UPDATE_UNIVERSITY_SUCCESS = "UPDATE_UNIVERSITY_SUCCESS";
export const UPDATE_UNIVERSITY_FAIL = "UPDATE_UNIVERSITY_FAIL";

export const DELETE_UNIVERSITY_REQUEST = "DELETE_UNIVERSITY_REQUEST";
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS";
export const DELETE_UNIVERSITY_FAIL = "DELETE_UNIVERSITY_FAIL";

export const CLEAR_UNIVERSITY_RECORD = "CLEAR_UNIVERSITY_RECORD";
