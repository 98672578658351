import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SET_CURRENT_USER,
  SIGNIN_USER_ERROR,
  SIGNOUT_USER,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  SET_SUCCESS_FALSE,
  SET_ERROR_FALSE,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CREATE_REQUEST,
  USER_PASSWORD_CREATE_SUCCESS,
  USER_PASSWORD_CREATE_FAIL,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_FORGET_PASSWORD_FAIL,
  USER_EMAIL_PASSWORD_REQUEST,
  USER_EMAIL_PASSWORD_SUCCESS,
  USER_EMAIL_PASSWORD_FAIL,
} from "../constant/AuthConstant";
const intialState = {
  isSigninIn: false,
  isSignUp: false,
  loading: false,
  action: "Authentication",
  userResult: {},
  singledata: [],
  success: false,
  response: [],
  result: [],
  msg: "",
  error: false,
  message: "",
};

const AuthReducer = (state = intialState, action) => {
  switch (action.type) {
    case SIGNIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        isSigninIn: true,
        loading: false,
        message: action.payload,
        success: true,
      };
    case SIGNIN_USER_ERROR:
      return {
        ...state,
        isSigninIn: false,
        loading: false,
        message: action.payload,
        error: action.error,
      };

    case USER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        isSignUp: action.isSignUp,
        result: action.result,
        success: true,
        loading: false,
        msg: action.msg,
      };
    case USER_REGISTER_FAIL:
      return {
        ...state,
        isSignUp: false,
        msg: action.msg,
        loading: false,
        error: true,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        userResult: action.payload,
        isSigninIn: true,
        loading: false,
      };
    case SIGNOUT_USER:
      return {
        ...state,
        isSigninIn: false,
      };
    case SET_MESSAGE:
      return {
        message: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        message: action.payload,
      };
    case SET_SUCCESS_FALSE:
      return {
        ...state,
        success: false,
      };
    case SET_ERROR_FALSE:
      return {
        ...state,
        error: false,
      };

    case USER_PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };
    case USER_PASSWORD_CHANGE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case USER_PASSWORD_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_PASSWORD_CREATE_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: false,
        msg: action.msg,
        success: true,
      };
    case USER_PASSWORD_CREATE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        msg: action.msg,
      };
    case USER_FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        msg: action.msg,
        success: true,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
      };
    case USER_FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        msg: action.msg,
        error: action.error,
      };
    case USER_EMAIL_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_EMAIL_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        // result: state.result.concat[(action.payload)],
        msg: action.msg,
      };
    case USER_EMAIL_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        msg: action.msg,
        error: action.error,
      };

    default:
      return state;
  }
};

export default AuthReducer;
