import {
  GET_ALL_EMPLOYEE_FAIL,
  GET_ALL_EMPLOYEE_REQUEST,
  GET_ALL_EMPLOYEE_SUCCESS,
  GET_SINGLE_EMPLOYEE_FAIL,
  GET_SINGLE_EMPLOYEE_REQUEST,
  GET_SINGLE_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_FAIL,
  EMPLOYEE_PICTURE_UPDATE_REQUEST,
  EMPLOYEE_PICTURE_UPDATE_SUCCESS,
  EMPLOYEE_PICTURE_UPDATE_FAIL,
  CLEAR_EMPLOYEE_RECORD,
  CLEAR_EMPLOYEE_SINGLE_RECORD,
} from "../constant/EmployeeConstant";
const initialState = {
  loading: false,
  action: "Employee",
  result: [],
  singledata: [],
  error: "",
  success: false,
  message: "",
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GET_ALL_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    case GET_SINGLE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.payload,
      };
    case ADD_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.payload,
      };
    case UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case EMPLOYEE_PICTURE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_PICTURE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,

        message: action.message,
      };
    case EMPLOYEE_PICTURE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.result),
        message: action.message,
      };
    case DELETE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_EMPLOYEE_RECORD:
      return {
        ...state,
        loading: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    case CLEAR_EMPLOYEE_SINGLE_RECORD:
      return {
        ...state,
        loading: false,

        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default EmployeeReducer;
