// constant files

export const ADD_HOLIDAYLIST_REQUEST = "ADD_HOLIDAYLIST_REQUEST";
export const ADD_HOLIDAYLIST_SUCCESS = "ADD_HOLIDAYLIST_SUCCESS";
export const ADD_HOLIDAYLIST_FAIL = "ADD_HOLIDAYLIST_FAIL";

export const UPDATE_HOLIDAYLIST_REQUEST = "UPDATE_HOLIDAYLIST_REQUEST";
export const UPDATE_HOLIDAYLIST_SUCCESS = "UPDATE_HOLIDAYLIST_SUCCESS";
export const UPDATE_HOLIDAYLIST_FAIL = "UPDATE_HOLIDAYLIST_FAIL";

export const DELETE_HOLIDAYLIST_REQUEST = "DELETE_HOLIDAYLIST_REQUEST";
export const DELETE_HOLIDAYLIST_SUCCESS = "DELETE_HOLIDAYLIST_SUCCESS";
export const DELETE_HOLIDAYLIST_FAIL = "DELETE_HOLIDAYLIST_FAIL";

export const GETALL_HOLIDAYLIST_REQUEST = "GETALL_HOLIDAYLIST_REQUEST";
export const GETALL_HOLIDAYLIST_SUCCESS = "GETALL_HOLIDAYLIST_SUCCESS";
export const GETALL_HOLIDAYLIST_FAIL = "GETALL_HOLIDAYLIST_FAIL";

export const GETDETAIL_HOLIDAYLIST_REQUEST = "GETDETAIL_HOLIDAYLIST_REQUEST";
export const GETDETAIL_HOLIDAYLIST_SUCCESS = "GETDETAIL_HOLIDAYLIST_SUCCESS";
export const GETDETAIL_HOLIDAYLIST_FAIL = "GETDETAIL_HOLIDAYLIST_FAIL";

export const CLEAR_HOLIDAY_RECORD = "CLEAR_HOLIDAY_RECORD";
