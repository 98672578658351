// constant files
export const GET_ALL_DESIGNATION_REQUEST = "GET_ALL_DESIGNATION_REQUEST";
export const GET_ALL_DESIGNATION_SUCCESS = "GET_ALL_DESIGNATION_SUCCESS";
export const GET_ALL_DESIGNATION_FAIL = "GET_ALL_DESIGNATION_FAIL";

export const GET_SINGLE_DESIGNATION_REQUEST = "GET_SINGLE_DESIGNATION_REQUEST";
export const GET_SINGLE_DESIGNATION_SUCCESS = "GET_SINGLE_DESIGNATION_SUCCESS";
export const GET_SINGLE_DESIGNATION_FAIL = "GET_SINGLE_DESIGNATION_FAIL";

export const ADD_DESIGNATION_REQUEST = "ADD_DESIGNATION_REQUEST";
export const ADD_DESIGNATION_SUCCESS = "ADD_DESIGNATION_SUCCESS";
export const ADD_DESIGNATION_FAIL = "ADD_DESIGNATION_FAIL";

export const UPDATE_DESIGNATION_REQUEST = "UPDATE_DESIGNATION_REQUEST";
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_FAIL = "UPDATE_DESIGNATION_FAIL";

export const DELETE_DESIGNATION_REQUEST = "DELETE_DESIGNATION_REQUEST";
export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_FAIL = "DELETE_DESIGNATION_FAIL";

export const CLEAR_DESIGNATION_RECORD = "CLEAR_DESIGNATION_RECORD";
