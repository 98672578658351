// constant files

export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL";

export const GET_ALL_EMPLOYEE_REQUEST = "GET_ALL_EMPLOYEE_REQUEST";
export const GET_ALL_EMPLOYEE_SUCCESS = "GET_ALL_EMPLOYEE_SUCCESS";
export const GET_ALL_EMPLOYEE_FAIL = "GET_ALL_EMPLOYEE_FAIL";

export const GET_SINGLE_EMPLOYEE_REQUEST = "GET_SINGLE_EMPLOYEE_REQUEST";
export const GET_SINGLE_EMPLOYEE_SUCCESS = "GET_SINGLE_EMPLOYEE_SUCCESS";
export const GET_SINGLE_EMPLOYEE_FAIL = "GET_SINGLE_EMPLOYEE_FAIL";

export const EMPLOYEE_PICTURE_UPDATE_REQUEST = "EMPLOYEE_PICTURE_UPDATE_REQUEST";
export const EMPLOYEE_PICTURE_UPDATE_SUCCESS = "EMPLOYEE_PICTURE_UPDATE_SUCCESS";
export const EMPLOYEE_PICTURE_UPDATE_FAIL = "EMPLOYEE_PICTURE_UPDATE_FAIL";

export const CLEAR_EMPLOYEE_RECORD = " CLEAR_EMPLOYEE_RECORD";
export const CLEAR_EMPLOYEE_SINGLE_RECORD = "CLEAR_EMPLOYEE_SINGLE_RECORD";
