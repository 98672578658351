// constant files

export const ADD_DOCUMENT_TYPE_REQUEST = "ADD_DOCUMENT_TYPE_REQUEST";
export const ADD_DOCUMENT_TYPE_SUCCESS = "ADD_DOCUMENT_TYPE_SUCCESS";
export const ADD_DOCUMENT_TYPE_FAIL = "ADD_DOCUMENT_TYPE_FAIL";

export const UPDATE_DOCUMENT_TYPE_REQUEST = "UPDATE_DOCUMENT_TYPE_REQUEST";
export const UPDATE_DOCUMENT_TYPE_SUCCESS = "UPDATE_DOCUMENT_TYPE_SUCCESS";
export const UPDATE_DOCUMENT_TYPE_FAIL = "UPDATE_DOCUMENT_TYPE_FAIL";

export const DELETE_DOCUMENT_TYPE_REQUEST = "DELETE_DOCUMENT_TYPE_REQUEST";
export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT_TYPE_FAIL = "DELETE_DOCUMENT_TYPE_FAIL";

export const GETALL_DOCUMENT_TYPE_REQUEST = "GETALL_DOCUMENT_TYPE_REQUEST";
export const GETALL_DOCUMENT_TYPE_SUCCESS = "GETALL_DOCUMENT_TYPE_SUCCESS";
export const GETALL_DOCUMENT_TYPE_FAIL = "GETALL_DOCUMENT_TYPE_FAIL";

export const GETDETAIL_DOCUMENT_TYPE_REQUEST =
  "GETDETAIL_DOCUMENT_TYPE_REQUEST";
export const GETDETAIL_DOCUMENT_TYPE_SUCCESS =
  "GETDETAIL_DOCUMENT_TYPE_SUCCESS";
export const GETDETAIL_DOCUMENT_TYPE_FAIL = "GETDETAIL_DOCUMENT_TYPE_FAIL";

export const CLEAR_DOCUMENTS_RECORD = "CLEAR_DOCUMENTS_RECORD";
