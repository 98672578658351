import {
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAIL,
  GET_SINGLE_ACTIVITY_REQUEST,
  GET_SINGLE_ACTIVITY_SUCCESS,
  GET_SINGLE_ACTIVITY_FAIL,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  CLEAR_ACTIVITY_RECORD,
} from "../constant/ActivityConstant";

const initialState = {
  loading: false,
  action: "ACTIVITY",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const ActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };

    case GET_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GET_SINGLE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_ACTIVITY_FAIL:
      return {
        loading: false,
        error: action.error,
        message: action.message,
      };

    case CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };

    case CREATE_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case UPDATE_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: action.error,
      };
    case DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case CLEAR_ACTIVITY_RECORD:
      return {
        ...state,
        loading: false,
        success: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default ActivityReducer;
