export const ADD_LEAVE_APPROVE_REQUEST = "ADD_LEAVE_APPROVE_REQUEST";
export const ADD_LEAVE_APPROVE_SUCCESS = "ADD_LEAVE_APPROVE_SUCCESS";
export const ADD_LEAVE_APPROVE_FAIL = "ADD_LEAVE_APPROVE_FAIL";

export const UPDATE_LEAVE_APPROVE_REQUEST = "UPDATE_LEAVE_APPROVE_REQUEST";
export const UPDATE_LEAVE_APPROVE_SUCCESS = "UPDATE_LEAVE_APPROVE_SUCCESS";
export const UPDATE_LEAVE_APPROVE_FAIL = "UPDATE_LEAVE_APPROVE_FAIL";

export const DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST";
export const DELETE_LEAVE_SUCCESS = "DELETE_LEAVE_SUCCESS";
export const DELETE_LEAVE_FAIL = "DELETE_LEAVE_FAIL";

export const GETALL_LEAVE_REQUEST = "GETALL_LEAVE_REQUEST";
export const GETALL_LEAVE_SUCCESS = "GETALL_LEAVE_SUCCESS";
export const GETALL_LEAVE_FAIL = "GETALL_LEAVE_FAIL";

export const GETDETAIL_LEAVE_REQUEST = "GETDETAIL_LEAVE_REQUEST";
export const GETDETAIL_LEAVE_SUCCESS = "GETDETAIL_LEAVE_SUCCESS";
export const GETDETAIL_LEAVE_FAIL = "GETDETAIL_LEAVE_FAIL";

export const CLEAR_LEAVE_RECORD = "CLEAR_LEAVE_RECORD";
