export const GETALL_ADDRESS_REQUEST = "GETALL_ADDRESS_REQUEST";
export const GETALL_ADDRESS_SUCCESS = "GETALL_ADDRESS_SUCCESS";
export const GETALL_ADDRESS_FAIL = "GETALL_ADDRESS_FAIL";

export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";

export const UPDATE_EMPLOYEE_ADDRESS_REQUEST = "UPDATE_EMPLOYEE_ADDRESS_REQUEST";
export const UPDATE_EMPLOYEE_ADDRESS_SUCCESS = "UPDATE_EMPLOYEE_ADDRESS_SUCCESS";
export const UPDATE_EMPLOYEE_ADDRESS_FAIL = "UPDATE_EMPLOYEE_ADDRESS_FAIL";
