// constant files
export const GET_ALL_BANK_REQUEST = "GET_ALL_BANK_REQUEST";
export const GET_ALL_BANK_SUCCESS = "GET_ALL_BANK_SUCCESS";
export const GET_ALL_BANK_FAIL = "GET_ALL_BANK_FAIL";

export const GET_SINGLE_BANK_REQUEST = "GET_SINGLE_BANK_REQUEST";
export const GET_SINGLE_BANK_SUCCESS = "GET_SINGLE_BANK_SUCCESS";
export const GET_SINGLE_BANK_FAIL = "GET_SINGLE_BANK_FAIL";

export const ADD_BANK_REQUEST = "ADD_BANK_REQUEST";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_FAIL = "ADD_BANK_FAIL";

export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAIL = "UPDATE_BANK_FAIL";

export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAIL = "DELETE_BANK_FAIL";

export const BANK_CLEAR_RECORD = "BANK_CLEAR_RECORD";
