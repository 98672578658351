import {
  ADD_LEAVE_FINANCIAL_FAIL,
  ADD_LEAVE_FINANCIAL_SUCCESS,
  ADD_LEAVE_FINANCIAL_REQUEST,
  DELETE_LEAVE_FINANCIAL_REQUEST,
  DELETE_LEAVE_FINANCIAL_SUCCESS,
  GETDETAIL_LEAVE_FINANCIAL_REQUEST,
  GETDETAIL_LEAVE_FINANCIAL_FAIL,
  GETDETAIL_LEAVE_FINANCIAL_SUCCESS,
  UPDATE_LEAVE_FINANCIAL_FAIL,
  UPDATE_LEAVE_FINANCIAL_SUCCESS,
  UPDATE_LEAVE_FINANCIAL_REQUEST,
  DELETE_LEAVE_FINANCIAL_FAIL,
  GETALL_LEAVE_FINANCIAL_FAIL,
  GETALL_LEAVE_FINANCIAL_SUCCESS,
  GETALL_LEAVE_FINANCIAL_REQUEST,
  CLEAR_LEAVE_FINANCIAL_RECORD,
} from "../constant/LeaveFinancialYearConstant";

const initialState = {
  loading: false,
  action: "Leave_Financial_Year",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const LeaveFinancialYearReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_LEAVE_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_LEAVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_LEAVE_FINANCIAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GETDETAIL_LEAVE_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETDETAIL_LEAVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GETDETAIL_LEAVE_FINANCIAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case ADD_LEAVE_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_LEAVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case ADD_LEAVE_FINANCIAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,

        message: action.message,
      };

    case UPDATE_LEAVE_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LEAVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case UPDATE_LEAVE_FINANCIAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };

    case DELETE_LEAVE_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LEAVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_LEAVE_FINANCIAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_LEAVE_FINANCIAL_RECORD:
      return {
        ...state,
        loading: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default LeaveFinancialYearReducer;
