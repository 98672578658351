export const GET_ACTIVITY_REQUEST = "GET_ACTIVITY_REQUEST";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const GET_SINGLE_ACTIVITY_REQUEST = "GET_SINGLE_ACTIVITY_REQUEST";
export const GET_SINGLE_ACTIVITY_SUCCESS = "GET_SINGLE_ACTIVITY_SUCCESS";
export const GET_SINGLE_ACTIVITY_FAIL = "GET_SINGLE_ACTIVITY_FAIL";

export const CREATE_ACTIVITY_REQUEST = "CREATE_ACTIVITY_REQUEST";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAIL = "CREATE_ACTIVITY_FAIL";

export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL";

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAIL = "CDELETEACTIVITY_FAIL";

export const CLEAR_ACTIVITY_RECORD = "CLEAR_ACTIVITY_RECORD";
