import {
  GET_ALL_CITY_FAIL,
  GET_ALL_CITY_REQUEST,
  GET_ALL_CITY_SUCCESS,
  GET_SINGLE_CITY_FAIL,
  GET_SINGLE_CITY_REQUEST,
  GET_SINGLE_CITY_SUCCESS,
  ADD_CITY_FAIL,
  ADD_CITY_REQUEST,
  ADD_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_REQUEST,
  DELETE_CITY_FAIL,
} from "../constant/CityConstant";
const initialState = {
  loading: false,
  action: "City",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const CityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        message: action.message,
      };
    case GET_ALL_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GET_SINGLE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case ADD_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case ADD_CITY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,

        message: action.message,
      };

    case UPDATE_CITY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case UPDATE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case DELETE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.result),
        message: action.message,
      };
    case DELETE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};

export default CityReducer;
