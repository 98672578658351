import {
  GET_SCOPE_REQUEST,
  GET_SCOPE_SUCCESS,
  GET_SCOPE_FAIL,
  GET_SINGLE_SCOPE_REQUEST,
  GET_SINGLE_SCOPE_SUCCESS,
  GET_SINGLE_SCOPE_FAIL,
  CREATE_SCOPE_REQUEST,
  CREATE_SCOPE_SUCCESS,
  CREATE_SCOPE_FAIL,
  UPDATE_SCOPE_REQUEST,
  UPDATE_SCOPE_SUCCESS,
  UPDATE_SCOPE_FAIL,
  DELETE_SCOPE_REQUEST,
  DELETE_SCOPE_SUCCESS,
  DELETE_SCOPE_FAIL,
  CLEAR_SCOPE_RECORD,
} from "../constant/ScopeConstant";

const initialState = {
  loading: false,
  action: "SCOPE",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

const ScopeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCOPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };

    case GET_SCOPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GET_SINGLE_SCOPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_SCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.message,
      };
    case GET_SINGLE_SCOPE_FAIL:
      return {
        loading: false,
        error: action.error,
        message: action.message,
      };

    case CREATE_SCOPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };

    case CREATE_SCOPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case UPDATE_SCOPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case UPDATE_SCOPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: action.error,
      };
    case DELETE_SCOPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: state.result.filter((item) => item.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_SCOPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case CLEAR_SCOPE_RECORD:
      return {
        ...state,
        loading: false,
        success: false,
        result: [],
        singledata: [],
        message: action.message,
      };
    default:
      return state;
  }
};

export default ScopeReducer;
